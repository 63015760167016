<template>
  <div>
    <header class="top__header bg-primary text-white">
      <div class="container-lg">
        <div class="row">
          <div class="col-md-8">
            <h1 class="main__header mb-2 text-uppercase">
              {{ productDetails.title }}
            </h1>
            <p class="txt__sm" v-html="productDetails.short_description"></p>
            <div class="product__card__tags">
              <a
                href="javascript:void(0);"
                class="product-tag"
                v-for="(industry, j) in productDetails.industries"
                :key="j"
                >{{ industry }}</a
              >
            </div>
          </div>
          <div class="col-md-4">
            <div class="product__uploader product__uploader--left">
              <div class="mr-3 text-left text-md-right">
                <p class="txt__sm">
                  <router-link
                    :to="{
                      path: '/user-profile',
                      query: { profile: productDetails.seller_id },
                    }"
                  >
                    <div>
                      <p class="txt__10 text-white">Uploaded by</p>
                      <p class="text-white">{{ productDetails.seller_name }}</p>
                    </div>
                  </router-link>
                </p>
                <!-- {{productDetails}} -->
              </div>
              <router-link
                :to="{
                  path: '/user-profile',
                  query: { profile: productDetails.seller_id },
                }"
              >
                <div class="avatar avatar--white mr-0">
                  {{ firstChar(productDetails.seller_name) | capitalize }}
                </div>
              </router-link>
            </div>
            <div class="product__uploader product__uploader--left">
              <button
                :class="[
                  'product__purchase__btn',
                  productDetails.purchased ? 'sold' : '',
                ]"
                @click="getpurchasemodalopen(productDetails.id)"
              >
                <svg
                  width="20"
                  height="18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.281 3.008C6.281 1.054 9.68 0 12.877 0s6.595 1.054 6.595 3.008V15.04c0 1.734-2.685 2.734-5.346 2.958l-.051.002a.6.6 0 01-.05-1.2c2.773-.233 4.248-1.171 4.248-1.76v-.628c-.698.446-1.674.8-2.893 1.015a.6.6 0 01-.209-1.184c2.016-.357 3.102-1.095 3.102-1.61v-.627c-.698.446-1.674.8-2.893 1.015a.6.6 0 01-.209-1.184c2.016-.357 3.102-1.096 3.102-1.61V9.6c-.698.448-1.674.8-2.893 1.016a.6.6 0 01-.209-1.184c2.016-.358 3.102-1.096 3.102-1.61v-.644c-1.03.658-2.585 1.064-4.147 1.195h-.005l-.046.002a.601.601 0 01-.05-1.2c2.773-.233 4.248-1.171 4.248-1.76v-.626c-1.272.805-3.373 1.228-5.396 1.228-2.023 0-4.124-.423-5.396-1.228v.626a.6.6 0 11-1.2 0V3.008zm1.2 0c0 .735 2.102 1.805 5.396 1.805s5.396-1.07 5.396-1.805c0-.735-2.102-1.805-5.396-1.805S7.48 2.273 7.48 3.008z"
                    fill="#fff"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 10.38c0-1.905 3.398-2.932 6.595-2.932 3.197 0 6.596 1.027 6.596 2.931v4.69C13.19 16.973 9.792 18 6.595 18S0 16.973 0 15.069v-4.69zm6.595 6.448c3.295 0 5.397-1.043 5.397-1.76v-.61c-1.273.784-3.374 1.197-5.397 1.197s-4.124-.413-5.396-1.197v.61c0 .717 2.102 1.76 5.396 1.76zm5.397-4.104c0 .716-2.102 1.759-5.397 1.759-3.294 0-5.396-1.043-5.396-1.759v-.61c1.272.784 3.373 1.196 5.396 1.196 2.023 0 4.124-.412 5.397-1.197v.611zM1.199 10.38c0 .717 2.102 1.759 5.396 1.759 3.295 0 5.397-1.042 5.397-1.759 0-.716-2.102-1.758-5.397-1.758-3.294 0-5.396 1.042-5.396 1.758z"
                    fill="#fff"
                  />
                </svg>

                {{
                  !productDetails.purchased
                    ? productDetails.price + "cr"
                    : "Purchased"
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>

    <div class="container-lg">
      <div class="product-details-page">
        <div class="row">
          <div class="col-md-8">
            <div class="product__summary">
              <div class="product__summary__image"></div>
              <div class="pt-3 px-4 pb-4">
                <p class="mb-3 txt__sm text-primary font-weight-bold">About</p>
                <p class="txt__sm" v-html="productDetails.long_description"></p>
              </div>
            </div>
            <!-- <div class="py-3">
              <p class="txt__md txt__light mb-1">
                1 years with you, let's celebrate!
              </p>
              <p class="txt__30 text-primary font-weight-bold mb-1">
                Save up to 50% on Premium
              </p>
              <p class="txt__sm">Offer valid until 9th of October 2021</p>
            </div> -->

            <!-- <div class="offers">
              <div class="offer__box bg__orange">
                <div class="mb-2">
                  <p class="txt__lg font-weight-bold mb-2">
                    Save up to 45% on Premium
                  </p>
                  <p class="txt__xs">Offer valid until 9th of October 2021</p>
                </div>

                <button class="btn btn-outline-light mb-2">Go Premium</button>
              </div>

              <div class="offer__box bg__light__blue">
                <div class="mb-2">
                  <p class="txt__lg font-weight-bold mb-2">
                    Save up to 30% on Premium
                  </p>
                  <p class="txt__xs">Offer valid until 9th of October 2021</p>
                </div>

                <button class="btn btn-outline-light mb-2">Go Premium</button>
              </div>

              <div class="offer__box bg__green">
                <div class="mb-2">
                  <p class="txt__lg font-weight-bold mb-2">
                    For 3 Month Subscriptoin
                  </p>
                  <p class="txt__xs">Offer valid until 9th of October 2021</p>
                </div>

                <button class="btn btn-outline-light mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.473"
                    height="18"
                    class="mr-2"
                  >
                    <path
                      d="M13.481 17.449a.6.6 0 01.547-.65c2.772-.232 4.247-1.171 4.247-1.759v-.628a8.023 8.023 0 01-2.894 1.016.526.526 0 01-.105.009.6.6 0 01-.1-1.193c2.016-.357 3.1-1.1 3.1-1.61v-.627a8.007 8.007 0 01-2.895 1.014.528.528 0 01-.105.01.6.6 0 01-.1-1.194c2.016-.357 3.1-1.1 3.1-1.609V9.6a7.99 7.99 0 01-2.895 1.015.528.528 0 01-.105.01.6.6 0 01-.1-1.193c2.016-.357 3.1-1.1 3.1-1.61v-.644a9.591 9.591 0 01-4.15 1.194h-.052a.6.6 0 01-.049-1.2c2.772-.232 4.247-1.171 4.247-1.76v-.624a10.855 10.855 0 01-5.391 1.228 10.855 10.855 0 01-5.4-1.228v.626a.6.6 0 11-1.2 0V3.008c0-1.954 3.4-3.008 6.6-3.008s6.6 1.054 6.6 3.008V15.04c0 1.734-2.686 2.734-5.346 2.957h-.052a.6.6 0 01-.602-.548z"
                      fill="#fff"
                    />
                    <path
                      data-name="Shape"
                      d="M0 15.068v-4.689c0-1.904 3.4-2.931 6.6-2.931s6.6 1.027 6.6 2.931v4.689c0 1.9-3.4 2.931-6.6 2.931S0 16.973 0 15.068z"
                      fill="#fff"
                    />
                  </svg>
                  3000Cr
                </button>
              </div>

              <div class="offer__box bg__purple">
                <div class="mb-2">
                  <p class="txt__lg font-weight-bold mb-2">
                    For 6 Month Subscriptoin
                  </p>
                  <p class="txt__xs">Offer valid until 9th of October 2021</p>
                </div>

                <button class="btn btn-outline-light mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.473"
                    height="18"
                    class="mr-2"
                  >
                    <path
                      d="M13.481 17.449a.6.6 0 01.547-.65c2.772-.232 4.247-1.171 4.247-1.759v-.628a8.023 8.023 0 01-2.894 1.016.526.526 0 01-.105.009.6.6 0 01-.1-1.193c2.016-.357 3.1-1.1 3.1-1.61v-.627a8.007 8.007 0 01-2.895 1.014.528.528 0 01-.105.01.6.6 0 01-.1-1.194c2.016-.357 3.1-1.1 3.1-1.609V9.6a7.99 7.99 0 01-2.895 1.015.528.528 0 01-.105.01.6.6 0 01-.1-1.193c2.016-.357 3.1-1.1 3.1-1.61v-.644a9.591 9.591 0 01-4.15 1.194h-.052a.6.6 0 01-.049-1.2c2.772-.232 4.247-1.171 4.247-1.76v-.624a10.855 10.855 0 01-5.391 1.228 10.855 10.855 0 01-5.4-1.228v.626a.6.6 0 11-1.2 0V3.008c0-1.954 3.4-3.008 6.6-3.008s6.6 1.054 6.6 3.008V15.04c0 1.734-2.686 2.734-5.346 2.957h-.052a.6.6 0 01-.602-.548z"
                      fill="#fff"
                    />
                    <path
                      data-name="Shape"
                      d="M0 15.068v-4.689c0-1.904 3.4-2.931 6.6-2.931s6.6 1.027 6.6 2.931v4.689c0 1.9-3.4 2.931-6.6 2.931S0 16.973 0 15.068z"
                      fill="#fff"
                    />
                  </svg>
                  3000Cr
                </button>
              </div>
            </div> -->

            <div class="px-4 py-3">
              <p class="txt__sm font-weight-bold">Sample</p>
            </div>

            <div class="table-responsive market-table bg-white">
              <table class="table wrapping mb-0">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Contacts</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ productDetails.file_name }}</td>
                    <td>{{ productDetails.row_count }}</td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        class="table-action-primary-btn"
                        @click="downloadFile(productDetails.id)"
                      >
                        <svg
                          data-name="Group 6"
                          xmlns="http://www.w3.org/2000/svg"
                          width="9"
                          height="10"
                        >
                          <path
                            d="M6.3 3.598v-3.6H2.7v3.6H0l4.5 4.5 4.5-4.5H6.3z"
                            fill="#fff"
                          />
                          <path
                            data-name="Line 5"
                            d="M.5 9.5h8"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="square"
                            stroke-miterlimit="10"
                          />
                        </svg>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table-responsive market-table bg-white mt-3">
              <table class="table mb-0">
                <thead>
                  <tr class="tr-badge-area bg-primary text-white">
                    <th
                      v-for="(heads, index) in productDetails.sample.columns"
                      :key="index"
                    >
                      {{ heads }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="table-area-wrap"
                    v-for="(body, index2) in productDetails.sample.body"
                    :key="index2"
                  >
                    <td v-for="(content, index3) in body" :key="index3">
                      {{ content }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-md-4">
            <div class="offer__box bg-white align-items-start rounded-0 mb-2">
              <div class="mb-2">
                <p class="txt__lg font-weight-bold mb-1 txt">
                  {{ productDetails.row_count }}
                </p>
                <p class="txt__sm text-muted">Data List</p>
              </div>
              <div class="mb-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="26.069"
                >
                  <g data-name="Group 5 Copy 3" opacity=".6">
                    <g
                      fill="none"
                      stroke="#0091ff"
                      stroke-miterlimit="10"
                      stroke-width="1.5"
                    >
                      <rect width="28" height="26.069" rx="4" stroke="none" />
                      <rect
                        x=".75"
                        y=".75"
                        width="26.5"
                        height="24.569"
                        rx="3.25"
                      />
                    </g>
                    <g data-name="Group 4" transform="translate(7.724 5.793)">
                      <ellipse
                        cx="3.361"
                        cy="3.335"
                        rx="3.361"
                        ry="3.335"
                        transform="translate(2.881)"
                        fill="#0091ff"
                      />
                      <path
                        d="M0 15.243a6 6 0 016.242-5.716 6 6 0 016.242 5.716"
                        fill="none"
                        stroke="#0091ff"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <div
              class="offer__box bg-white align-items-start rounded-0 mb-2"
              v-if="productDetails.seller_id != seller_id && productDetails.purchased"
            >
              <div class="mb-2">
                <p class="txt__sm text-muted">Add Review</p>
              </div>
              <div class="mb-2">
                <a
                  href="javascript:void(0);"
                  @click="openReviewModal(productDetails.id)"
                  :class="{ active: ReviewModalOpen }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    class="bi bi-plus-lg"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div class="product__card mb-2">
              <div class="product__card__header px-4">
                <p class="txt__md font-weight-bold txt mb-2">
                  Customer reviews
                </p>
                <p class="txt__sm txt__light">
                  {{ productDetails.total_reviews }} reviews
                </p>
                <div class="d-flex align-items-center pt-4 pb-2">
                  <Rating
                    v-bind:average_rating="productDetails.average_rating"
                  />
                  <p class="txt__sm mb-1">
                    {{ productDetails.average_rating }} out of 5
                  </p>
                </div>
              </div>
              <div class="py-3 px-4 star-area">
                <div
                  class="d-flex align-items-center justify-content-between mb-2"
                >
                  <div class="d-flex align-items-center mb-1">
                    <div class="product__reviews mr-2 mb-0">
                      <Rating v-bind:average_rating="5" />
                    </div>
                    <p class="txt__sm">5 star</p>
                  </div>
                  <p class="txt__sm mb-1">{{ productDetails.ratings[5] }}</p>
                </div>
                <div
                  class="d-flex align-items-center justify-content-between mb-2"
                >
                  <div class="d-flex align-items-center mb-1">
                    <div class="product__reviews mr-2 mb-0">
                      <Rating v-bind:average_rating="4" />
                    </div>
                    <p class="txt__sm">4 star</p>
                  </div>
                  <p class="txt__sm mb-1">{{ productDetails.ratings[4] }}</p>
                </div>
                <div
                  class="d-flex align-items-center justify-content-between mb-2"
                >
                  <div class="d-flex align-items-center mb-1">
                    <div class="product__reviews mr-2 mb-0">
                      <Rating v-bind:average_rating="3" />
                    </div>
                    <p class="txt__sm">3 star</p>
                  </div>
                  <p class="txt__sm mb-1">{{ productDetails.ratings[3] }}</p>
                </div>
                <div
                  class="d-flex align-items-center justify-content-between mb-2"
                >
                  <div class="d-flex align-items-center mb-1">
                    <div class="product__reviews mr-2 mb-0">
                      <Rating v-bind:average_rating="2" />
                    </div>
                    <p class="txt__sm">2 star</p>
                  </div>
                  <p class="txt__sm mb-1">{{ productDetails.ratings[2] }}</p>
                </div>
                <div
                  class="d-flex align-items-center justify-content-between mb-2"
                >
                  <div class="d-flex align-items-center mb-1">
                    <div class="product__reviews mr-2 mb-0">
                      <Rating v-bind:average_rating="1" />
                    </div>
                    <p class="txt__sm">1 star</p>
                  </div>
                  <p class="txt__sm mb-1">{{ productDetails.ratings[1] }}</p>
                </div>
              </div>
            </div>

            <div class="offer__box bg-white align-items-start rounded-0 mb-2">
              <div class="mb-2">
                <p class="txt__lg font-weight-bold mb-1 txt">
                  {{ productDetails.download }}
                </p>
                <p class="txt__sm text-muted">Download</p>
              </div>
              <div class="mb-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-cloud-arrow-down text-primary"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708l2 2z"
                  />
                  <path
                    d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"
                  />
                </svg>
              </div>
            </div>

            <div class="offer__box bg-white align-items-start rounded-0 mb-2">
              <div class="mb-2">
                <p class="txt__lg font-weight-bold mb-1 txt">
                  {{ productDetails.view }}
                </p>
                <p class="txt__sm text-muted">View</p>
              </div>
              <div class="mb-2">
                <svg
                  width="33"
                  height="24"
                  viewBox="0 0 33 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.7068 12.9748C12.3169 12.5839 11.6831 12.5839 11.2932 12.9748C10.9023 13.3647 10.9023 13.9986 11.2932 14.3885C11.6831 14.7794 12.3169 14.7794 12.7068 14.3885C13.0977 13.9986 13.0977 13.3647 12.7068 12.9748Z"
                    fill="#0091FF"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15 16.1816C15 15.3545 15.6729 14.6816 16.5 14.6816C17.3271 14.6816 18 15.3545 18 16.1816C18 17.0087 17.3271 17.6816 16.5 17.6816C15.6729 17.6816 15 17.0087 15 16.1816ZM16 16.1816C16 16.4573 16.2243 16.6816 16.5 16.6816C16.7757 16.6816 17 16.4573 17 16.1816C17 15.9059 16.7757 15.6816 16.5 15.6816C16.2243 15.6816 16 15.9059 16 16.1816Z"
                    fill="#0091FF"
                  />
                  <path
                    d="M16 10.6816C15.3014 10.6816 14.6258 10.8228 13.9922 11.1013C13.6762 11.2403 13.5327 11.609 13.6716 11.925C13.8104 12.241 14.1793 12.3845 14.4952 12.2457C14.9693 12.0373 15.4756 11.9316 16 11.9316C18.0677 11.9316 19.75 13.6139 19.75 15.6816C19.75 17.7494 18.0677 19.4316 16 19.4316C13.9323 19.4316 12.25 17.7494 12.25 15.6816C12.25 15.6603 12.2499 15.6394 12.2504 15.618C12.2584 15.273 11.9852 14.9867 11.6401 14.9787C11.2945 14.9701 11.0088 15.2439 11.0008 15.589C11.0001 15.6201 11 15.6505 11 15.6816C11 18.4386 13.243 20.6816 16 20.6816C18.757 20.6816 21 18.4386 21 15.6816C21 12.9246 18.757 10.6816 16 10.6816Z"
                    fill="#0091FF"
                  />
                  <path
                    d="M17 0.681641C16.4477 0.681641 16 0.961453 16 1.30664V5.05664C16 5.40183 16.4477 5.68164 17 5.68164C17.5523 5.68164 18 5.40183 18 5.05664V1.30664C18 0.961453 17.5523 0.681641 17 0.681641Z"
                    fill="#0091FF"
                  />
                  <path
                    d="M9.85924 6.86114L7.82049 4.8224C7.63281 4.63472 7.32849 4.63472 7.14076 4.8224C6.95308 5.01008 6.95308 5.3144 7.14076 5.50212L9.17951 7.54086C9.36719 7.72854 9.67156 7.72859 9.85924 7.54086C10.0469 7.35319 10.0469 7.04887 9.85924 6.86114Z"
                    fill="#0091FF"
                  />
                  <path
                    d="M25.8592 4.8224C25.6716 4.63472 25.3672 4.63472 25.1795 4.8224L23.1408 6.86114C22.9531 7.04882 22.9531 7.35314 23.1408 7.54086C23.3284 7.72854 23.6328 7.72859 23.8205 7.54086L25.8592 5.50212C26.0469 5.31445 26.0469 5.01012 25.8592 4.8224Z"
                    fill="#0091FF"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.5 7.68164C22.6647 7.68164 28.7442 11.3611 32.8386 15.7869C33.0742 16.0416 33.0489 16.4305 32.7822 16.6555C32.5145 16.8811 32.1073 16.8555 31.8724 16.6016C31.735 16.4532 31.5943 16.3053 31.4518 16.1582C28.2669 20.7529 22.4933 23.6816 16.5 23.6816C10.5067 23.6816 4.73311 20.7529 1.54816 16.1582C1.40565 16.3052 1.26495 16.4531 1.1276 16.6016C0.892026 16.8563 0.484682 16.8805 0.217846 16.6555C-0.0489257 16.4305 -0.0741913 16.0416 0.161449 15.7869C4.25506 11.362 10.3345 7.68164 16.5 7.68164ZM2.46926 15.2521C5.3837 19.6399 10.8336 22.4509 16.5 22.4509C22.1664 22.4509 27.6163 19.6399 30.5307 15.252C28.2344 13.1037 22.9286 8.91241 16.5 8.91241C10.0715 8.91241 4.7656 13.1037 2.46926 15.2521Z"
                    fill="#0091FF"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div class="color-block-custom" v-if="productReviews.length">
          <div
            class="
              d-flex
              align-items-center
              justify-content-between
              py-3
              px-4
              border-bottom
            "
          >
            <p class="txt__md font-weight-bold">Product Reviews</p>
            <select
              class="custom-select custom-select--list"
              v-model="sort"
              @change="getProductReview"
            >
              <option selected value="">Sort By</option>
              <option value="desc">Positive</option>
              <option value="asc">Negative</option>
            </select>
          </div>

          <div
            class="user-review"
            v-for="(productReview, index) in productReviews"
            :key="index"
          >
            <div
              class="
                d-flex
                align-items-center
                justify-content-between
                py-3
                px-4
                flex-wrap
                pb-4
              "
            >
              <div class="d-flex align-items-center mb-2">
                <div class="avatar flex-shrink-0">
                  {{ firstChar(productReview.reviewer_name) | capitalize }}
                </div>
                <p class="txt__sm">{{ productReview.reviewer_name }}</p>
              </div>

              <div class="product__reviews mb-2">
                <Rating v-bind:average_rating="productReview.rating" />
              </div>
            </div>
            <div class="pb-2 px-4">
              <div class="pb-3">
                <p class="txt__sm">
                  {{ productReview.review }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="
            d-flex
            align-items-center
            justify-content-between
            py-3
            px-4
            flex-wrap
            pb-4
            color-block-custom
          "
          v-else
        >
          <p class="txt__md font-weight-bold">No Reviews</p>
        </div>
        <div class="pt-3">
          <button
            class="full-width-action-btn color-block-custom"
            v-if="load_more && !loading"
            @click="getMoreProductReview"
          >
            See More
          </button>
          <button
            class="spin-wrap bg-white border-none out w-100 color-block-custom"
            v-if="loading"
          >
            <i class="fas fa-circle-notch fa-spin"></i>
          </button>
        </div>
        <div class="py-4 pl-4">
          <p class="txt__sm font-weight-bold">Related Lists</p>
        </div>
        <div class="form-row">
          <div
            class="col-sm-4"
            v-for="(relatedProduct, index) in relatedProductDetails"
            :key="index"
          >
            <div class="product__card">
              <div class="product__card__header px-4">
                <p
                  class="txt__md font-weight-bold txt"
                  @click="showProduct(index, relatedProduct.id)"
                >
                  {{ relatedProduct.title }}
                </p>
                <div
                  class="product__card__tags"
                  v-if="relatedProduct.industries.length > 0"
                >
                  <a
                    href="javascript:void(0);"
                    class="product-tag"
                    v-for="(industry, j) in relatedProduct.industries"
                    :key="j"
                  >
                    {{ industry }}
                  </a>
                </div>
              </div>
              <div class="product__card__review px-4">
                <div class="product__card__reviews mb-2">
                  <Rating
                    v-bind:average_rating="relatedProduct.average_rating"
                  />
                  <p class="txt__sm">
                    {{ relatedProduct.total_review }} reviews
                  </p>
                </div>
                <div class="product__card__reviews__update mb-2">
                  <p class="txt__xs mb-2 text-muted">Updated on</p>
                  <p class="txt__xs">
                    {{ formatDate(relatedProduct.updated_at) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MODAL -->
    <ReviewModal
      @REVIEW_VALUE_CHANGED="getProductDetails"
      @SHOW_REVIEW="getProductReview"
    ></ReviewModal>
    <Dialog />
  </div>
</template>

<script>
import { axiosP, axiosHeaderP } from "@/services/axiosinstance";
import Rating from "@/components/common/Rating";
import ReviewModal from "@/components/modal/ReviewModal";
import Dialog from "@/components/modal/Dialog";

export default {
  name: "marketplace",
  components: {
    Rating,
    ReviewModal,
    Dialog,
  },

  data() {
    return {
      productDetails: [],
      relatedProductDetails: [],
      productReviews: [],
      sort: "",
      pagination: {
        to: "",
        total: "",
      },
      page: 1,
      load_more: false,
      loading: false,
      user_id: localStorage.getItem("uid"),
    };
  },
  created() {
    //  console.log(this.$route.query.product)
    this.getProductDetails();
    this.getProductReview();
    // this.getRelatedProduct();
  },
  methods: {
    openReviewModal(id) {
      this.$modal.show("ReviewModal", {
        data: JSON.parse(JSON.stringify(id)),
      });
    },
    temp(a) {
      var notreturn = 5 - parseInt(a);
      return notreturn;
    },
    firstChar(seller) {
      if (seller) {
        return seller.charAt(0);
      } else {
        return;
      }
    },
    formatDate(date) {
      if (date) {
        return this.$moment(date).format("MM/DD/YYYY");
      } else {
        return "N/A";
      }
    },
    async downloadFile(id) {
      this.formData = new FormData();
      this.formData.append("product_id", id);
      await axiosHeaderP
        .post("download-file", this.formData)
        .then((res) => {
          let url = res.data;
          const link = document.createElement("a");
          link.href = url;
          link.click();
        })
        .catch((err) => {
          // console.log(err);
          this.$toasted.show(err.response.data.message, {
            className: "error-class",
          });
        });
    },
    async getProductDetails() {
      // check that user is logged-in or not 
      var instance = axiosP;
    if (this.$store.getters.getLoggedIn) {
        instance = axiosHeaderP;
      }
      instance
        .post("product-details?product_id=" + this.$route.query.product)
        .then((res) => {
          if (res.data.status) {
            this.productDetails = res.data.data;
            // console.log(this.productDetails);
          } else {
            this.productDetails = [];
          }
          this.getRelatedProduct();
        })
        .catch(() => {
          this.productDetails = [];
          // console.log(err);
        });
    },
    async getRelatedProduct() {
      this.formData = new FormData();
      this.formData.append("product_id", this.productDetails.id);
      this.formData.append("industries", this.productDetails.industries);
      await axiosP
        .post("related-product", this.formData)
        .then((res) => {
          if (res.data.status) {
            this.relatedProductDetails = res.data.data;
          } else {
            this.relatedProductDetails = [];
          }
        })
        .catch(() => {
          this.relatedProductDetails = [];
          // console.log(err);
        });
    },
    async getProductReview() {
      this.formData = new FormData();
      this.formData.append("page", this.page);
      this.formData.append("limit", "2");
      this.formData.append("seller_id", "");
      this.formData.append("product_id", this.$route.query.product);
      this.formData.append("parent_id", "");
      this.formData.append("sort_order", this.sort);
      await axiosP
        .post("get-reviews", this.formData)
        .then((res) => {
          if (res.data.status) {
            this.productReviews = res.data.data;
            this.load_more = res.data.pagination.load_more;
            this.pagination = res.data.pagination;
          } else {
            this.productReviews = [];
            this.load_more = false;
          }
        })
        .catch(() => {
          this.load_more = false;
          this.productReviews = [];
          // console.log(err);
        });
    },
    async getMoreProductReview() {
      this.loading = true;
      // this.getProducts();
      this.page++;
      this.formData = new FormData();
      this.formData.append("page", this.page);
      this.formData.append("limit", "2");
      this.formData.append("seller_id", "");
      this.formData.append("product_id", this.$route.query.product);
      this.formData.append("parent_id", "");
      this.formData.append("sort_order", this.sort);
      await axiosP
        .post("get-reviews", this.formData)
        .then((res) => {
          if (res.data.status) {
            //   this.productReviews = res.data.data;
            // } else {
            //   this.productReviews = [];
            // }
            let productReviews = res.data.data;
            productReviews.map((val) => {
              this.productReviews.push(val);
            });
            this.load_more = res.data.pagination.load_more;
            this.pagination = res.data.pagination;
          } else {
            this.load_more = false;
          }
          this.loading = false;
        })
        .catch(() => {
          this.productReviews = [];
          this.loading = false;
          // console.log(err);
        });
    },
    showProduct(index, id) {
      this.productDetails = this.relatedProductDetails[index];
      this.$router.replace({
        name: "MarketplaceDetails",
        query: {
          product: id,
        },
      });
      this.getProductDetails();
      document.documentElement.scrollTop = 0;
    },
    getpurchasemodalopen(id, index) {
      this.$modal.show("Dialog", {
        title: "Are you sure?",
        text: "Do you want to purchase this list?",
        buttons: [
          {
            title: "No",
            handler: () => {
              this.$modal.hide("Dialog");
            },
          },
          {
            title: "Yes",
            handler: () => {
              this.purchase(id, index);
            },
          },
        ],
      });
    },

    async purchase(id) {
      if (this.$store.getters.getLoggedIn) {
        this.formData = new FormData();
        this.formData.append("product_id", id);
        await axiosHeaderP
          .post("purchase", this.formData)
          .then((res) => {
            if (res.data.status) {
              // this.profile();
              this.$store.dispatch(
                "setUserCredit",
                res.data.data.current_credit
              );

              this.$toasted.show(res.data.message, {
                icon: "check",
                className: "succes-class",
              });
              this.$modal.hide("Dialog");
              this.productDetails.purchased = true;

              // window.location.reload();
            }
            //  console.log(res.data);
          })
          .catch((err) => {
            console.log(err);
            this.$toasted.show(err.response.data.message, {
              icon: "times-circle",
              className: "error-class",
            });
            this.$modal.hide("Dialog");
          });
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.margin0 {
  margin: 0;
  background: blue;
}
.table-area-wrap td {
  background-color: #6fbffd75;
}
.spin-wrap {
  outline: none;
  border: none;
  padding: 10px 0;
}

.fa-spin {
  color: #007bff;
  font-size: 30px;
}

.color-block-custom {
  margin-top: 10px;
  background: #bde2fe;
  box-shadow: 0px 0px 18px 0px #80808029;
}
.offer__box {
  box-shadow: 0px 0px 18px 0px #80808029;
}

.star-area {
  box-shadow: 0px 0px 18px 0px #80808029;
  margin-top: 5px;
}
.product__summary {
  box-shadow: 0px 0px 18px 0px #80808029;
}
</style>
