<template>
  <modal
    name="ReviewModal"
    :classes="'customModal'"
    :scrollable="true"
    :reset="true"
    :adaptive="true"
    width="400px"
    height="auto"
    :shiftY="0.5"
    :clickToClose="false"
    @before-open="getData"
    @before-close="clear"

  >
    <p class="txt__md text-primary font-weight-bold mb-1">Give Feedback</p>
    <p class="pt-3 modal-text-wrap">How did we do?</p>
    <div class="row">
      <div
        class="
          rating-wrapper
          w-100
          mb-5
          py-1
          col-12 col-md-10 col-lg-8 col-xl-7
          d-flex
          align-items-left
        "
      >
        <input
          type="radio"
          name="rating"
          id="star-1"
          v-model="rating"
          value="5"
        /><label for="star-1"></label>
        <input
          type="radio"
          name="rating"
          id="star-2"
          v-model="rating"
          value="4"
        /><label for="star-2"></label>
        <input
          type="radio"
          name="rating"
          id="star-3"
          v-model="rating"
          value="3"
        /><label for="star-3"></label>
        <input
          type="radio"
          name="rating"
          id="star-4"
          v-model="rating"
          value="2"
        /><label for="star-4"></label>
        <input
          type="radio"
          name="rating"
          id="star-5"
          v-model="rating"
          value="1"
        /><label for="star-5"></label>
      </div>

      <div class="col-sm-12">
        <div class="mb-3">
          <p class="txt__md modal-text-wrap2nd mb-2">
            Care to share more about it?
          </p>
          <div class="input-group mb-3">
            <textarea
              v-model="review"
              rows="4"
              type="text"
              class="form-control"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>
          <button
            class="btn text-white bg-primary w-100"
            @click="onSubmitReview"
          >
            PUBLISH FEEDBACK
          </button>
        </div>
      </div>
    </div>

    <button @click="closeModal()" class="close-modal">x</button>
  </modal>
</template>

<script>
export default {
  name: "ReviewModal",
  data() {
    return {
      product_id: "",
      rating: "",
      review: "",
    };
  },
  methods: {
    closeModal() {
      this.$modal.hide("ReviewModal");
    },
    getData(e) {
      this.product_id = e.params.data;
      // console.log(this.product_id)
    },
    clear()
    {
      // console.log('si')
      this.product_id = "";
      this.rating = "";
      this.review = "";
    },
    onSubmitReview() {
      this.formData = new FormData();
      this.formData.append("product_id", this.product_id);
      this.formData.append("user_rating", this.rating);
      this.formData.append("review", this.review);
      window.axiosHeaderP
        .post("give-rating", this.formData)
        .then((res) => {
          this.$emit("REVIEW_VALUE_CHANGED");
          this.$emit("SHOW_REVIEW");
          this.$toasted.show(res.data.message, {
            icon: "check-circle",
            className: "success-class",
          });
          this.closeModal();
        })
        .catch((err) => {
          this.$toasted.show(err.response.data.message, {
            className: "success-class",
          });
          this.closeModal();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-text-wrap2nd {
  font-size: 14px;
  font-weight: 400;
}
.modal-text-wrap {
  font-size: 14px;
  font-weight: 600;
}
.rating-wrapper {
  direction: rtl;
}
.rating-wrapper input {
  display: none;
}
.rating-wrapper label {
  // display: inline-block;
  width: 50px;
  margin: auto;
  position: relative;
  cursor: pointer;
}
.rating-wrapper label:before {
  content: "\2605";
  position: absolute;
  font-size: 20px;
  // display: inline-block;
  // margin-top: -58% !important;
  left: 0;
  color: rgb(191, 191, 191);
}
.rating-wrapper label:after {
  content: "\2605";
  position: absolute;
  font-size: 20px;
  // display: inline-block;
  // margin-top: -58% !important;
  left: 0;
  color: #ffb019;
  opacity: 0;
}
.rating-wrapper label:hover:after,
.rating-wrapper label:hover ~ label:after,
.rating-wrapper input:checked ~ label:after {
  opacity: 1;
}
.rating-wrapper label:hover::after {
  opacity: 1;
}
</style>